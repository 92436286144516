/* 
//
// Custom Styling for Login & Signup flow
//
*/

.authentication-box {
  margin-top: -250px !important;
}

/* 
//
// Custom Styling for Heading
//
*/

.page-main-header .main-header-right .nav-right > ul {
  float: right !important;
}

.page-main-header .main-header-right .nav-right > ul > li:first-child {
  width: 100% !important;
}
